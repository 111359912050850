// import logo from './logo.svg';
// import '../styles/Texty.css';
import $ from 'jquery';

function Texty() {

    var words = ['Hi I Like To Build Websites', 'Let\'s Get Creative', 'Do You Have An Idea?', 'Let\'s Make It Happen', ''],
    part,
    i = 0,
    offset = 0,
    len = words.length,
    forwards = true,
    skip_count = 0,
    skip_delay = 15,
    speed = 70;
var wordflick = function () {
  setInterval(function () {
    if (forwards) {
      if (offset >= words[i].length) {
        ++skip_count;
        if (skip_count === skip_delay) {
          forwards = false;
          skip_count = 0;
        }
      }
    }
    else {
      if (offset === 0) {
        forwards = true;
        i++;
        offset = 0;
        if (i >= len) {
          i = 0;
        }
      }
    }
    part = words[i].substr(0, offset);
    if (skip_count === 0) {
      if (forwards) {
        offset++;
      }
      else {
        offset--;
      }
    }
    $('.word').text(part);
  },speed);
};

$(document).ready(function () {
  wordflick();
});


  return (
    <div className="Texty">
        {/* <h1>I am a </h1> */}
        <div className="word" style={{height: '100px'}}>
        

        </div>

    </div>
  );
}

export default Texty;
