import React, { useState, useEffect } from "react";
import Logo from "../assets/nchlogo2.jpg";
// import PizzaLeft from "../assets/gradient.jpeg";
// import { Link } from "react-router-dom";
// import Logo2 from "../assets/bitcoin_logo_2222.svg";
import {HashLink as Link} from 'react-router-hash-link';
import ReorderIcon from "@material-ui/icons/Reorder";
import CloseIcon from '@mui/icons-material/Close';
import SailingIcon from '@mui/icons-material/Sailing';
import "../styles/Navbar2.css";

function Navbar2() {

  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const [checked, setChecked] = useState(false);

  const handleCheck =() => {
    setChecked(!checked)
   }
  

// const controlNavbar = () => {
//   if (typeof window !== 'undefined') { 
//     if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
//       setShow(true); 
//     } else { // if scroll up show the navbar
//       setShow(false);  
//     }

//     // remember current page location to use in the next move
//     setLastScrollY(window.scrollY); 
//   }
// };

// useEffect(() => {
//   if (typeof window !== 'undefined') {
//     window.addEventListener('scroll', controlNavbar);
//     // cleanup function
//     return () => {
//       window.removeEventListener('scroll', controlNavbar);
//     };
//   }
// }, [lastScrollY]);




  return (
    <div >
    <div >
    <nav >
      <div className={`navbar2 active2 ${show && 'hidden2'}`}>
        <div className="container nav-container">
          <img className="logo" src={Logo} />
            {/* <h3 style={{color: '#7A2002'}}>LOGO HERE</h3> */}
          {/* </div> */}
            <input className="checkbox" type="checkbox" 
            // name="" 
            // id="checkclick" 
            checked={checked} 
            onClick={handleCheck}
            />
            <div className="theme">
              {/* <a href="https://oceanhero.today/search"> */}
              <div>

                {/* <p>Ocean Theme</p> */}
                <SailingIcon></SailingIcon>
              </div>
              {/* </a> */}
            </div>
            <div className="hamburger-lines" onClick={handleCheck}>
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
          <div className="menu-items">
            <li onClick={handleCheck}><Link smooth to="#home">Home</Link></li>
            <li onClick={handleCheck}><Link smooth to="#podcast">Our Work</Link></li>
            {/* <li><Link to="/gallery">Gallery</Link></li> */}
            {/* <li onClick={handleCheck}><Link smooth to="#menu">Services</Link></li> */}
            <li onClick={handleCheck}><Link smooth to="#contact">Contact</Link></li>
          </div>
        </div>
      </div>
    </nav>
  </div>

  </div>
  );
}

export default Navbar2;