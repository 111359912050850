import React from "react";
import "../styles/Banner.css";

function Banner() {
  return (
    <div className="banner">
      <p> Keeping Prices Fair Despite Inflation Rise  -</p>
      <p> - Get Your Free Quote Today</p>
    </div>
  );
}

export default Banner;
