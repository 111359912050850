import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import BannerImage from "../assets/qr.jpg";
import Texty from "../components/Texty";
// import Logo from "../assets/th.jpg";
// import Logo2 from "../assets/logo.svg";
// import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import "../styles/Home.css";
import Marquee from 'react-fast-marquee';
import {HashLink as Link} from 'react-router-hash-link';
// import img1 from '../assets/map.png';
// import img2 from '../assets/qr.jpg';
// import img3 from '../assets/map.png';
// import img4 from '../assets/moon.png';
import img5 from '../assets/sundial1.jpg';

function Home() {

  
  return (

    

    <div className="home" id="home" 
    style={{ backgroundImage: `url(${img5})`
              // transform: [{rotate: '90deg' }]
  }}
    >
      {/* <img src={Logo} className="App-logo" alt="logo" /> */}

      <div className="headerContainer">
        <div 
        // style={{ backgroundImage: `url(${Logo})`, width:"150px", height:"150px"}}
        ></div>
        <Texty/>
        <h2> Your Local Web Designer </h2>
        {/* <p> Eye Candy homePage that showcases what you're good at.  </p> */}
        <p>   We Design And Host Your Website For Half The Price. </p>
          <Link smooth to="#contact">
          <button>
              {/* <CurrencyBitcoinIcon fontSize="large" /> */}
                Free Estimate
          </button>
          </Link>
      </div>
        {/* <div className="qr" style={{ backgroundImage: `url(${BannerImage})` }}> */}

        
         {/* </div> */}

        <div>
          {/* <h3>Trusted By The Community</h3>
        <Marquee direction="right" speed={60} pauseOnHover='true'
                  // delay={0}
                  gradientColor={[0,0,0]}
        >
          <div className="image_wrapper">
            <a href="https://open.spotify.com/episode/4gAcCRbSNRPdxsW5fYwL1H">
              <img src={img1} alt="" />
            </a>
          </div>
          <div className="image_wrapper">
          <a href="https://open.spotify.com/episode/4gAcCRbSNRPdxsW5fYwL1H">
              <img src={img2} alt="" />
            </a>
          </div>
          <div className="image_wrapper">
            <a href="https://open.spotify.com/episode/4gAcCRbSNRPdxsW5fYwL1H">
              <img src={img3} alt="" />
            </a>
          </div>
          <div className="image_wrapper">
            <a href="https://open.spotify.com/episode/4gAcCRbSNRPdxsW5fYwL1H">
              <img src={img4} alt="" />
            </a>
          </div>
          <div className="image_wrapper">
            <a href="https://open.spotify.com/episode/4gAcCRbSNRPdxsW5fYwL1H">
              <img src={img5} alt="" />
            </a>
          </div>
          <div className="image_wrapper">
            <a href="https://open.spotify.com/episode/4gAcCRbSNRPdxsW5fYwL1H">
              <img src={img1} alt="" />
            </a>
          </div>
          <div className="image_wrapper">
            <a href="https://open.spotify.com/episode/4gAcCRbSNRPdxsW5fYwL1H">
              <img src={img2} alt="" />
            </a>
          </div>
          <div className="image_wrapper">
            <a href="https://open.spotify.com/episode/4gAcCRbSNRPdxsW5fYwL1H">
              <img src={img3} alt="" />
            </a>
          </div>
          <div className="image_wrapper">
            <a href="https://open.spotify.com/episode/4gAcCRbSNRPdxsW5fYwL1H">
              <img src={img4} alt="" />
            </a>
          </div>
          <div className="image_wrapper">
            <a href="https://open.spotify.com/episode/4gAcCRbSNRPdxsW5fYwL1H">
              <img src={img5} alt="" />
            </a>
          </div>
          </Marquee> */}

      </div>

    </div>
  );
}


export default Home;
