import "./App.css";
// import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ImageSlider from "./components/ImageCarousel";
import Home from "./pages/Home";
// import Menu from "./pages/Menu";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Banner from "./components/Banner";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Podcast from "./pages/Podcast";
import Navbar2 from "./components/Navbar2";
// import Community from "./pages/Community";
// import Gallery from "./pages/Gallery";
import HoriParallax from "./components/HoriParallax";
import MultiplePizzas from "./assets/fishphoto.jpg";

function App() {

  if (window.location.href != "https://www.norcalhost.com/") {
    // console.log('works great!!');
    window.location = "https://www.norcalhost.com";
  }


  return (
    <div className="App"
    // style={{ backgroundImage: `url(${MultiplePizzas})` }} 
    >
      <Router>
        {/* <Navbar /> */}
        <Switch>
          <Route exact path="/">
            <Navbar2 />
            {/* <Banner /> */}
            <Home />
            <About />
        <Podcast />
            {/* <Menu /> */}
            <HoriParallax/>
            <Contact />
          </Route>
        </Switch>
        {/* <Navbar2 /> */}
        {/* <Banner /> */}
        {/* <Home /> */}
        {/* <About /> */}
        {/* <Community /> */}
        {/* <Menu /> */}
        {/* <Contact /> */}
        {/* <Switch> */}
          {/* <Route path="/" exact component={Home} /> */}
          {/* <Route path="/gallery" exact component={Gallery} /> */}
          {/* <Route path="/about" exact component={About} /> */}
          {/* <Route path="/contact" exact component={Contact} /> */}
        {/* </Switch> */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
