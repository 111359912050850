import React from "react";
import { PodcastList } from "../helpers/PodcastList";
import PodcastItem from "../components/PodcastItem";
import "../styles/Podcast.scss";
import Button from '@mui/material/Button';
import MicIcon from '@mui/icons-material/Mic';
import Marquee from 'react-fast-marquee';

function Podcast() {
  

  // var spotify = "https://open.spotify.com/show/6PZHzYSBrxGLhmitd4aKKj";

  return (
    <div className="top">
    <div className="podcast" id="podcast"
    // style={{ backgroundImage: `url(${Pizza})` }}s
    >
      <div className="podcastTitle"
        style={{zindex:0}}
      
      >

      <Marquee speed="5" 
      // gradient="false" 
      // gradientColor={[0,0,0]}
                gradientWidth="0"  

      >


      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>
      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>
      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>
      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>
      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>
      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>
      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>
      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>
      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>
      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>
      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>

      <MicIcon fontSize="large"></MicIcon>
      <h2>Our Work</h2>

      
      
      </Marquee>
      
        {/* <div id="titleImage" style={{ backgroundImage: `url(${PizzaMap})` }}></div> */}

      </div>
      <div className="podcastList">

              {PodcastList.map((podcastItem, key) => {
                return (


                  <PodcastItem
                  key={key}
                  linkTo={podcastItem.linkTo}
                  image={podcastItem.image}
                  name={podcastItem.name}
                  description={podcastItem.description}
                  // price={menuItem.price}
                  />
                  );
                })}
                
      </div>
        
        {/* <div className="podBut">
            <a href={spotify}>
            <Button variant="contained">MORE</Button>
            </a>
        </div> */}
    </div>
    </div>

    


  );
}

export default Podcast;
