import React from "react";
import "../styles/About.css";
import pic2 from "../assets/business1.jpg";
import Slider from "../components/ImageCarousel";


function About() {
  return (
    <div 
    className="about" id="about">
      <div className="aboutTop">
        
      </div>

        <div className="aboutLeft">
          <Slider></Slider>
        </div>
        <div className="aboutRight">

          <h1> How do you want to be seen? </h1>

          <img src={pic2} style={{width:"100%", hieght:"auto"}}></img>

          <p style={{fontSize:20,
                     fontFamily: 'Rubix',
                     fontStyle: 'italic'}}>
                We move your ideas out on the big blue sea that is the internet.  Our ship or
                should i say partnerSHIP will be closer to finding  
                the whales of the world.
                Data analytics that show high numbers of unique visitors to your Website.

          </p>
          {/* <h1> ? </h1>
          <p style={{fontSize:20,
                     fontFamily: 'Rubix',
                     fontStyle: 'italic'}}>
                We move your ideas out on the big blue sea that is the internet.  Our ship or
                should i say partnerSHIP will be closer to finding  
                the whales of the world.
                Data analytics that show high numbers of unique visitors to your Website.

          </p> */}
        </div>
    </div>
  );
}

export default About;
