import React, { Component } from "react";
import { Link } from "react-router-dom";
// import MovingComponent from 'react-moving-text';


function PodcastItem({ image, name, description,linkTo, price }) {
  return (
    <div className="podcastItem">

                {/* <MovingComponent
                    type="fadeInFromBottom"
                    duration="1000ms"
                    delay="0s"
                    direction="normal"
                    timing="ease"
                    iteration="1"
                    fillMode="none"> */}
                      {/* //insert code */}
        <div className="leftSide">
          <a href={linkTo}>
            <div style={{ backgroundImage: `url(${image})` }}> </div>
          </a> 

        </div>
        
        <div className="rightSide">
          <a href={linkTo}>
            <h3> {name} </h3>
            <p> {description} </p>
          </a> 
        </div>
      
      {/* <p> ${price} </p> */}



                {/* </MovingComponent> */}
    </div>

  );
}

export default PodcastItem;